import { init as initAddress } from '../form/address'
import { init as initBankInput } from '../form/bank'
import { init as initEmployerInput } from '../form/employer'
import { init as initEmployerContractEndInput } from '../form/employerContractEnd'
import { init as initIdentityCardInput } from '../form/identityCard'
import { init as initMoneySourceInput } from '../form/moneySource'
import { init as initMaritalStatusInput } from '../form/maritalStatus'
import { init as initNationalityInput } from '../form/nationality'
import { init as initPromoCodeInput } from '../form/promoCode'

export default function () {
    initAddress()
    initBankInput()
    initEmployerInput()
    initEmployerContractEndInput()
    initIdentityCardInput()
    initMaritalStatusInput()
    initMoneySourceInput()
    initNationalityInput()
    initPromoCodeInput()
}
